import Cookies from 'js-cookie';
import { IFrameHelper } from '../sdk/IFrameHelper';
import {
  getBubbleView,
  getDarkMode,
  getWidgetStyle,
} from '../sdk/settingsHelper';
import {
  computeHashForUserData,
  getUserCookieName,
  hasUserKeys,
} from '../sdk/cookieHelpers';
import { addClasses, loadCSS, removeClasses } from '../sdk/DOMHelpers';
import { SDK_SET_BUBBLE_VISIBILITY } from 'shared/constants/sharedFrameEvents';

const runSDK = async ({ baseUrl, websiteToken, isSecondWidget }) => {
  if (window.$hoory) {
    return;
  }

  /**
   * Fetch settings from the API
   */
  let hoorySettings = window.hoorySettings || {};
  try {
    const settingsFromApi = await fetch(
      `${baseUrl}/api/v1/sdk?token=${websiteToken}`
    ).then(res => res.json());
    if (settingsFromApi && settingsFromApi.name) {
      hoorySettings = { ...hoorySettings, ...settingsFromApi };
      window.hoorySettings = hoorySettings;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  let locale = hoorySettings.locale;
  if (hoorySettings.useBrowserLanguage) {
    locale = window.navigator.language.replace('-', '_');
  }

  window.$hoory = {
    baseUrl,
    hasLoaded: false,
    isSecondWidget,
    isOpen: false,
    isLoading: false,
    websiteToken,
    lazyLoad: hoorySettings.lazyLoad,
    allowWidgetMaximize: hoorySettings.allowWidgetMaximize || 'enabled',
    locale,
    escapeUnreadView: hoorySettings.escapeUnreadView || true,
    autoOpenUnreadConversation:
      hoorySettings.autoOpenUnreadConversation || false,
    hideMessageBubble: hoorySettings.hideMessageBubble || false,
    forceManualLinkManagement: hoorySettings.forceManualLinkManagement || false,
    position: hoorySettings.position === 'left' ? 'left' : 'right',
    useBrowserLanguage: hoorySettings.useBrowserLanguage || false,
    type: getBubbleView(hoorySettings.type),
    launcherTitle: hoorySettings.launcherTitle || '',
    showPopoutButton: hoorySettings.showPopoutButton || false,
    widgetStyle: getWidgetStyle(hoorySettings.widgetStyle) || 'standard',
    darkMode: getDarkMode(hoorySettings.darkMode),
    resetTriggered: false,

    toggle(state) {
      IFrameHelper.events.toggleBubble(state);
    },

    showConversation(state) {
      IFrameHelper.events.showConversation(state);
    },

    toggleBubbleVisibility(visibility) {
      let widgetElm = document.querySelector('.woot--bubble-holder');
      let widgetHolder = document.querySelector('.woot-widget-holder');
      if (visibility === 'hide') {
        addClasses(widgetHolder, 'woot-widget--without-bubble');
        addClasses(widgetElm, 'woot-hidden');
        window.$hoory.hideMessageBubble = true;
      } else if (visibility === 'show') {
        removeClasses(widgetElm, 'woot-hidden');
        removeClasses(widgetHolder, 'woot-widget--without-bubble');
        window.$hoory.hideMessageBubble = false;
      }
      IFrameHelper.sendMessage(SDK_SET_BUBBLE_VISIBILITY, {
        hideMessageBubble: window.$hoory.hideMessageBubble,
      });
    },

    popoutChatWindow() {
      IFrameHelper.events.popoutChatWindow({
        baseUrl: window.$hoory.baseUrl,
        websiteToken: window.$hoory.websiteToken,
        locale,
      });
    },

    setUser(identifier, user) {
      if (typeof identifier !== 'string' && typeof identifier !== 'number') {
        throw new Error('Identifier should be a string or a number');
      }

      if (!hasUserKeys(user)) {
        throw new Error(
          'User object should have one of the keys [avatar_url, email, name]'
        );
      }

      const userCookieName = getUserCookieName();
      const existingCookieValue = Cookies.get(userCookieName);
      const hashToBeStored = computeHashForUserData({ identifier, user });
      if (hashToBeStored === existingCookieValue) {
        return;
      }

      window.$hoory.identifier = identifier;
      window.$hoory.user = user;
      IFrameHelper.sendMessage('set-user', { identifier, user });
      Cookies.set(userCookieName, hashToBeStored, {
        expires: 365,
        sameSite: 'Lax',
      });
    },

    setCustomAttributes(customAttributes = {}) {
      if (!customAttributes || !Object.keys(customAttributes).length) {
        throw new Error('Custom attributes should have at least one key');
      } else {
        IFrameHelper.sendMessage('set-custom-attributes', { customAttributes });
      }
    },

    deleteCustomAttribute(customAttribute = '') {
      if (!customAttribute) {
        throw new Error('Custom attribute is required');
      } else {
        IFrameHelper.sendMessage('delete-custom-attribute', {
          customAttribute,
        });
      }
    },

    setConversationCustomAttributes(customAttributes = {}) {
      if (!customAttributes || !Object.keys(customAttributes).length) {
        throw new Error('Custom attributes should have at least one key');
      } else {
        IFrameHelper.sendMessage('set-conversation-custom-attributes', {
          customAttributes,
        });
      }
    },

    deleteConversationCustomAttribute(customAttribute = '') {
      if (!customAttribute) {
        throw new Error('Custom attribute is required');
      } else {
        IFrameHelper.sendMessage('delete-conversation-custom-attribute', {
          customAttribute,
        });
      }
    },

    setLabel(label = '') {
      IFrameHelper.sendMessage('set-label', { label });
    },

    removeLabel(label = '') {
      IFrameHelper.sendMessage('remove-label', { label });
    },

    setLocale(localeToBeUsed = 'en') {
      IFrameHelper.sendMessage('set-locale', { locale: localeToBeUsed });
    },

    reset() {
      if (window.$hoory.isOpen) {
        IFrameHelper.events.toggleBubble();
      }

      Cookies.remove('cw_conversation');
      Cookies.remove('cw_conversation2');
      Cookies.remove(getUserCookieName());

      const iframe = IFrameHelper.getAppFrame();
      iframe.contentWindow.location.replace(
        IFrameHelper.getUrl({
          baseUrl: window.$hoory.baseUrl,
          websiteToken: window.$hoory.websiteToken,
        })
      );

      window.$hoory.resetTriggered = true;
    },
  };

  /**
   * If lazy load is enabled, load the widget immediately
   */
  if (hoorySettings.lazyLoad) {
    loadCSS();
    IFrameHelper.onLoad({ widgetColor: hoorySettings.widgetColor || '#000' });

    /**
     * Let the parent window know that the widget is ready
     */
    if (isSecondWidget) {
      window.parent.postMessage('widget-window-is-ready', '*');
    }

    /**
     * Autoload the widget after 12s if the widget is not loaded
     */
    setTimeout(() => {
      if (!window.$hoory.hasLoaded) {
        window.$hoory.lazyLoad = false;
        window.$hoory.isLoading = true;
        IFrameHelper.createFrame({
          baseUrl,
          websiteToken,
          isSecondWidget,
        });
      }
    }, 12000);
    return;
  }

  /**
   * If the widget is loading regularly, add iframe to the body
   */
  IFrameHelper.createFrame({
    baseUrl,
    websiteToken,
    isSecondWidget,
  });
};

window.hoorySDK = {
  run: runSDK,
  remove: () => {
    window.$hoory = undefined;
    window.hoorySettings = undefined;
    window.hooryCurrentConversation = undefined;
    window.hooryCurrentConversationDid = undefined;
    IFrameHelper.removeMessageCommunicationHandlers();
  },
  async runSecond({ websiteToken, baseUrl, ...options }) {
    const settings = options.settings || {};
    loadCSS();
    const iframe = document.createElement('iframe');
    iframe.className = `second-hoory-iframe ${
      settings.position === 'right' ? 'right-position' : ''
    }`;
    iframe.style.backgroundColor = 'transparent';
    iframe.frameBorder = '0';
    iframe.allowTransparency = 'true';
    if (settings.hideMessageBubble) {
      iframe.classList.add('hide-message-bubble');
    }

    document.body.appendChild(iframe);

    const html = `<html>
    <head>
    <meta charset="UTF-8" />
    <title>Hoory second</title>
    <script id="hoory-sdk" src="${baseUrl}/packs/js/sdk.js" async defer ></script>
    </head>
    <script>
      window.hoorySettings = ${JSON.stringify(settings)};
      function initializeHoorySDK() {
        if (typeof hoorySDK !== 'undefined') {
          hoorySDK.run({
            websiteToken: '${websiteToken}',
            baseUrl: '${baseUrl}',
            isSecondWidget: true
          });
        } else {
          console.error('hoorySDK is not yet defined.');
        }
      }

      const script = document.getElementById('hoory-sdk');
      script.addEventListener('load', function() {
        initializeHoorySDK();
      });
    </script></html>`;

    // 1:
    // let settingParams = '';
    // Object.keys(settings).forEach(key => {
    //   settingParams += `&${key}=${settings[key]}`;
    // });
    // iframe.src = `${baseUrl}/api/v1/sdk/embed?token=${websiteToken}&baseURL=${baseUrl}${settingParams}`;

    // 2
    iframe.src = 'about: blank';
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();

    // 3
    // iframe.srcdoc = html;
    // iframe.sandbox = '';

    // 4
    // const blob = new Blob([html], { type: 'text/html', endings: "transparent" });
    // const url = URL.createObjectURL(blob);
    // iframe.src = url;
    // iframe.onload = () => URL.revokeObjectURL(url);

    // 5
    // iframe.src = 'data:text/html;charset=utf-8,' + encodeURIComponent(html);

    const iIframeChecker = () => {
      const isOpen = iframe.contentWindow.$hoory.isOpen;
      if (isOpen) iframe.classList.add('is-2d-active');
      else iframe.classList.remove('is-2d-active');
    };
    window.addEventListener('message', e => {
      if (e.data === 'lazy-load-second-widget') {
        iframe.classList.add('is-2d-active');
      } else if (e.data === 'widget-window-is-ready') {
        window.$hoory2 = iframe.contentWindow.$hoory;
      }
    });
    iframe.contentWindow.addEventListener('message', e => {
      window.$hoory2 = iframe.contentWindow.$hoory;
      if (typeof e.data !== 'string') return;
      const data = e.data.replace('chatwoot-widget:', '');
      try {
        const message = JSON.parse(data);
        if (message.event === 'closeWindow') {
          setTimeout(iIframeChecker, 50);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
    iframe.contentWindow.addEventListener('hoory:on-toggle', () => {
      if (window.innerWidth > 460) {
        iframe.contentWindow.document.body.classList.add('internal-widget');
      } else {
        iframe.contentWindow.document.body.classList.remove('internal-widget');
      }

      iIframeChecker();
    });

    iframe.contentWindow.document.addEventListener('hoory:ready', () => {
      window.$hoory2 = iframe.contentWindow.$hoory;
    });

    window.hoorySecondWindow = iframe.contentWindow;
    window.$hoory2 = iframe.contentWindow.$hoory;
  },
  reRun: ({ websiteToken }) => {
    const hoorySettings = window.hoorySettings || {};

    window.$hoory.avatar = hoorySettings.avatar || '';
    window.$hoory.forceManualLinkManagement =
      hoorySettings.forceManualLinkManagement || false;
    window.$hoory.websiteToken = websiteToken;
    window.$hoory.hideMessageBubble = hoorySettings.hideMessageBubble || false;
    window.$hoory.position =
      hoorySettings.position === 'left' ? 'left' : 'right';
    window.$hoory.useBrowserLanguage =
      hoorySettings.useBrowserLanguage || false;
    window.$hoory.type = getBubbleView(hoorySettings.type);
    window.$hoory.launcherTitle = hoorySettings.launcherTitle || '';
    window.$hoory.showPopoutButton = hoorySettings.showPopoutButton || false;
    window.$hoory.darkMode = getDarkMode(hoorySettings.darkMode);
    window.$hoory.widgetStyle =
      getWidgetStyle(hoorySettings.widgetStyle) || 'standard';
    window.$hoory.reset();
  },
};
